import React, {useCallback, useState} from "react";
import {TUTORIAL_ROUTE} from "../../Routes";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileArrowDown, faTimes} from "@fortawesome/pro-light-svg-icons";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {AnimatePresence, motion} from "motion/react";
import prettyBytes from "pretty-bytes";
import {useDropzone} from "react-dropzone";
import {logEvent} from "firebase/analytics";
import {analytics} from "../../firebase";
import {BrukerSelector} from "../BrukerSelector";
import {MachineType} from "../../typings";

type UploadDropZoneProps = {
    selectedFiles: File[];
    setSelectedFiles: (files: File[]) => void;
    setMachineType: (machineType?: MachineType) => void;
}

export const UploadDropZone: React.FC<UploadDropZoneProps> = ({selectedFiles, setSelectedFiles, setMachineType}) => {
    const [isBrukerSelectorModalOpen, setIsBrukerSelectorModalOpen] = useState<boolean>(false);

    const brukerImportFile = (file: File) => {
        setSelectedFiles([...selectedFiles, file]);
        setIsBrukerSelectorModalOpen(false);
        setMachineType("bruker");
    };

    const handleOpenBrukerSelectorModal = (
        e: React.MouseEvent<HTMLButtonElement>
    ) => {
        e.preventDefault();
        e.stopPropagation();
        setIsBrukerSelectorModalOpen(true);
    };

    const handleCloseBrukerSelectorModal = () => {
        setIsBrukerSelectorModalOpen(false);
    };

    const handleRemoveFile = (file: File) => () => {
        const newSelectedFiles = [...selectedFiles];
        newSelectedFiles.splice(newSelectedFiles.indexOf(file), 1);
        setSelectedFiles(newSelectedFiles);
    };

    const onDrop = useCallback(
        (acceptedFiles: File[]) => {
            logEvent(analytics, "user_added_files");
            setSelectedFiles([...selectedFiles, ...acceptedFiles]);
        },
        [selectedFiles, setSelectedFiles]
    );

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
    });

    const renderSelectedFiles = () => selectedFiles.map((file, i) => (
        <motion.div
            initial={{
                opacity: 0,
                y: 10
            }}
            animate={{
                opacity: 1,
                y: 0
            }}
            exit={{
                opacity: 0,
            }}
            layout={true}
            transition={{ duration: 0.1, delay: i * 0.01}}
            className="dz-details" key={file.name}>
            <div className="dz-filename text-break">
                <span>{file.name}</span>
            </div>
            <div className="dz-size text-nowrap">{prettyBytes(file.size)}</div>
            <button className="btn btn-outline-light dz-remove" type="button" onClick={handleRemoveFile(file)}>
                <FontAwesomeIcon icon={faTimes as IconProp}/>
            </button>
        </motion.div>
    ));

    return (
        <div className="upload-drop-zone">
            <BrukerSelector
                importFile={brukerImportFile}
                closeModal={handleCloseBrukerSelectorModal}
                isOpen={isBrukerSelectorModalOpen}
            />
            <div className="d-flex justify-content-between gap-40">
                <p>
                    Select your bruker compass experiment{" "}
                    <button
                        className="btn btn-link"
                        onClick={handleOpenBrukerSelectorModal}
                    >
                        here
                    </button>
                </p>
                <div>
                    <a href={TUTORIAL_ROUTE} target="_blank" rel="noreferrer">
                        <FontAwesomeIcon
                            icon={faFileArrowDown as IconProp}
                            className="mr-10"
                        />
                        upload instructions
                    </a>
                </div>
            </div>
            <div className="dropzone" id="dropzone">
                <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <div className="dz-message">
                        <p>
                            <strong>Drag and Drop files here,</strong>
                            <br/>
                            or click to select them from your computer
                            <br/>
                            or select your bruker compass experiment{" "}
                            <button
                                className="btn btn-link text-primary"
                                onClick={handleOpenBrukerSelectorModal}
                            >
                                here
                            </button>
                        </p>
                        <p className="small">(file size must not exceed 30MB)</p>
                    </div>
                </div>

                <motion.div layout className="dropzone-files pt-10">
                    <AnimatePresence >{renderSelectedFiles()}</AnimatePresence>
                </motion.div>

                {selectedFiles.length > 0 && (
                    <div className="dz-details dz-details__total">
                        <div className="dz-filename text-break fw-bold">
                      <span>
                        {selectedFiles.length}{" "}
                          {selectedFiles.length > 1 ? "files" : "file"} selected
                      </span>
                        </div>
                    </div>
                )}

            </div>
        </div>
    )
}